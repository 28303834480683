// Class to handle selects with dates
export default function DateSelect(prefix) {
  this.prefix = prefix + '_';
  this.selects = $(this.prefix + '1i,' + this.prefix + '2i,' + this.prefix + '3i');
}
DateSelect.HOUR = 3600000; // minutes * seconds * milliseconds
DateSelect.DAY = 24 * DateSelect.HOUR;
$.extend(DateSelect.prototype, {
  getDate: function() {
    var s = this.selects;
    return new Date(s.eq(2).val(), s.eq(1).val() - 1, s.eq(0).val());
  },
  setDate: function(string_date) {
    var date = new Date(string_date);
    this.selects.eq(0).val(date.getDate());
    this.selects.eq(1).val(date.getMonth() + 1);
    this.selects.eq(2).val(date.getFullYear());
  },
  daysDiff: function(other_date_select) {
    var firstTime = this.getDate().getTime();
    var secondTime = other_date_select.getDate().getTime();

    return Math.round((secondTime - firstTime) / DateSelect.DAY);
  },
  change: function(callable) {
    if (arguments.length) {
      this.selects.change(callable);
    } else {
      this.selects[0].change();
    }
  },
  prop: function(name, value) {
    this.selects.prop(name, value);
  }
});
