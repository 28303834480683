export default $(function() {
  $('.link_to_function_tenant_name').click(function() {
    let value;
    if (value = $('#user_name').val()) {
  	  $('#user_tenant_attributes_name').val(value);
    } else {
      alert($(this).data('error-message'));
    }
    return false;
  });
});
