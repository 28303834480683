import showFlash from 'showFlash';

// Generic AJAX error handler
export default $(function() {
  $('body').on('ajax:error', '[data-remote]', function(xhr) {
    let status = xhr.originalEvent.detail[2]
    if (status.status) {
      showFlash('danger', 'Remote request error (' + status.status + ': ' + status.statusText + ')', true);
    }
  });
});
