// Javascript filter without AJAX for select
export default function SelectFilter(selector) {
  this.target = $(selector);
  var options = this.target.find('option');
  this.include_blank = options.filter('[value=""]');

  this.search = function(filter) {
    var value = this.target.val();
    this.target.empty();
    this.target.append(this.include_blank);
    this.target.append(options.filter(filter));
    this.target.val(value).change();
  }
  this.change = function(callable) {
    if (arguments.length) {
      this.target.change(callable);
    } else {
      this.target.change();
    }
  },
  this.prop = function(name, value) {
    this.target.prop(name, value);
  }
}
