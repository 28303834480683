import DateSelect from './DateSelect';
import SelectFilter from './SelectFilter';

export function reduction_days(unexcused, earned_months) {
  var per_month = 2.5;
  switch (true) {
    case (unexcused >= 0 && unexcused <= 5):
      per_month = 0;
      break;
    case (unexcused >= 6 && unexcused <= 14):
      per_month = 0.5;
      break;
    case (unexcused >= 15 && unexcused <= 23):
      per_month = 1;
      break;
    case (unexcused >= 24 && unexcused <= 32):
      per_month = 1.5;
      break;
  }
  return per_month * earned_months;
}

var earned_days = 0;
var earned_months = 0;
var days_taken = 0;
var days_sold = 0;
export function vacation_form(prefix) {
  var prefix = '#' + prefix;
  var start_date = new DateSelect(prefix + 'start_date');
  var end_date = new DateSelect(prefix + 'end_date');
  var days_taken_input = $(prefix + 'days_taken');
  function update_days_taken() {
    if (end_date.getDate() >= start_date.getDate()) {
      days_taken_input.val(start_date.daysDiff(end_date) + 1);
    } else {
      days_taken_input.val('');
    }
  }
  function update_end_date() {
    var days = parseInt(days_taken_input.val()) - 1;
    end_date.setDate(start_date.getDate().getTime() + days * DateSelect.DAY);
  }
  $('#unexcused').keyup(function() {
    var x = $(this).val();
    var reduction = reduction_days(x, earned_months);
    var days_balance = earned_days - days_taken - days_sold - reduction;
    $('#reduction_days').text(I18n.toNumber(reduction, {strip_insignificant_zeros: true}));
    $('#days_balance').text(I18n.toNumber(days_balance, {strip_insignificant_zeros: true}));
  });
  start_date.change(update_end_date);
  end_date.change(update_days_taken);
  days_taken_input.keyup(update_end_date);
  update_days_taken(); // load
  mask_fields('#ajax-target');
}

var min_notice_days = 0;
export function termination_form(prefix) {
  if (!prefix) prefix = '#event_termination_attributes_';
  var date = new DateSelect(prefix + 'date');
  var termination_cause = new SelectFilter(prefix + 'termination_cause_id');
  var notice_start = new DateSelect(prefix + 'notice_start');
  var initiative = $(prefix + 'initiative');
  var notice = $(prefix + 'notice');
  function update_notice_start() {
    if (notice.val() == 'worked') {
      notice_start.prop('disabled', false);
      notice_start.setDate(date.getDate().getTime() - (min_notice_days - 1) * DateSelect.DAY);
    } else {
      notice_start.prop('disabled', true);
      notice_start.setDate(date.getDate().getTime());
    }
  }
  function update_termination_cause() {
    termination_cause.search('.' + initiative.val());
  }
  initiative.change(update_termination_cause);
  notice.change(update_notice_start);
  date.change(update_notice_start);
  update_notice_start(); // load
  update_termination_cause(); // load
  mask_fields('#ajax-target');
  termination_cause.change(function() {
    var selected = $(this).find('option:selected');
    notice.val(selected.data('notice')).change();
    $(prefix + 'trct_termination_cause_id').val(selected.data('trct'));
  });
}
