import datepicker from 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import I18n from 'I18n';

export function apply_datepicker(parent_selector) {
  $("[data-behaviour~='datepicker']", parent_selector).datepicker({
    'weekStart': 0,
    'language': 'pt-BR',
    'autoclose': true
  }).on('changeDate hide', function(ev){
    $('#' + $(this).data('target-input')).val(ev.date.getFullYear() + '-' + (ev.date.getMonth() + 1) + '-' + ev.date.getDate());
    $('#' + $(this).data('target-input-day')).val(ev.date.getDate());
    $('#' + $(this).data('target-input-month')).val(ev.date.getMonth() + 1);
    $('#' + $(this).data('target-input-year')).val(ev.date.getFullYear());
    if ($(this).data('submit')) {
      $(this).closest('form').submit();
    }
  }).on('show', function(ev){
    fixDatePickerPosition();
  });
  if ($('div.events-date #date_picker').length) {
    setInterval(function() {
      if ($('.datepicker-months').is(':visible')) {
        markMonthsWithEvents($('.datepicker-months'));
      } else {
        $('.datepicker-months').data('year', 0);
      }
    }, 200);
  }
}

$(window).resize(function() {
  setTimeout('fixDatePickerPosition()', 100);
});

export function fixDatePickerPosition() {
  if ($('.events-date').length) {
    $('.datepicker').css('left', ($('.events-date').offset().left + 10) + 'px');
  }
}

function markMonthsWithEvents(instance) {
  var thYear = instance.find('.datepicker-switch').text();
  if (instance.data('year') != thYear) {
    instance.data('year', thYear);
    $.get('/events/months_with_events', {year: thYear}, function(data) {
      $('.datepicker table span').removeClass('has-events').attr('title', '');
      $.each(data, function(index, value) {
        var parts = value.date.split('-');
        var date = new Date(parts[1] + '/' + parts[2] + '/' + parts[0]);
        $('.datepicker table span').eq(date.getMonth()).addClass('has-events').attr('title', value.count + ' ' + I18n.t('js.events'));
      });
    });
  }
}
