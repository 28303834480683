/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap';
import 'jquery';
import 'select2';
import lightbox from 'lightbox2';
import '../stylesheets/application';
import mask_fields from 'mask_fields';
import { apply_datepicker, fixDatePickerPosition } from 'apply_datepicker';
import Rails from 'rails-ujs';
import event_rows from 'event_rows';
import support_form from 'support_form';
import in_array from 'in_array';
import showFlash from 'showFlash';
import 'registrations';
import 'static_pages';
import 'generic_ajax_error_handler';
import * as events from '../events';

Rails.start();

$(() => {
  mask_fields();
  apply_datepicker();
  $('.select2').select2({
    theme: "bootstrap4"
  });
});

window.$ = $;
window.mask_fields = mask_fields;
window.support_form = support_form;
window.apply_datepicker = apply_datepicker;
window.in_array = in_array;
window.showFlash = showFlash;
window.fixDatePickerPosition = fixDatePickerPosition;

window.reduction_days = events.reduction_days
window.vacation_form = events.vacation_form
window.termination_form = events.termination_form

window.RecaptchaOptions = {
  theme: 'white'
};
