export default function showFlash(type, message, fadeOut, target){
    var target = target || 'div#content';
    var notice = $('#support-response', target);
    if (!notice.length) {
      notice = $('<div id="support-response" class="alert only-screen">');
      $(target).prepend(notice);
    }
    notice.removeClass('alert-warning');
    notice.removeClass('alert-success');
    notice.removeClass('alert-error');
    notice.addClass('alert-' + type);
    notice.html(message);
    notice.show();
    if (fadeOut) {
      notice.delay(10000).fadeOut();
    }
  }
