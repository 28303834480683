import 'jquery-maskmoney/dist/jquery.maskMoney';
import inputmask from "inputmask/dist/jquery.inputmask.bundle";
import regexMask from 'regexMask';

export default function mask_fields(parent_selector) {
  $('.currency,.percent', parent_selector)
    .each(function(){
      var obj = $(this)
      var value = obj.val()
      if (value.indexOf(',') == -1) { // ex.: 1234
        obj.val(parseFloat(value).toFixed(2));
      } else if (value.indexOf(',') == value.length - 2) { // ex.: 1234,5
        obj.val(value + '0')
      }
    })
    .maskMoney({thousands:'.', decimal:','})
    .bind('input paste', function() {
      if (typeof jQuery._data(this, 'events').mask != 'undefined') {
        var obj = $(this)
        var value = obj.val()
        setTimeout(function() {
          if (value.indexOf(',') == -1) { // ex.: 1234
            obj.val(value + ',00')
          } else if (value.indexOf(',') == value.length - 2) { // ex.: 1234,5
            obj.val(value + '0')
          }
          obj.maskMoney('mask');
        }, 1);
      }
    })
    .maskMoney('mask');
  $('.num', parent_selector).regexMask(/^\d+$/);
  $('.cnpj', parent_selector).inputmask('99.999.999/9999-99');
  $('.cpf', parent_selector).inputmask('999.999.999-99');
}
