export default $(document).ready(function(){
  if ($('#active_employees').height() > $('#forecast').height()) {
    $('#forecast').height($('#active_employees').height())
  } else {
    $('#active_employees').height($('#forecast').height())
  }
  if ($('#last_events').height() > $('#message_board').height()) {
    $('#message_board').height($('#last_events').height())
  } else {
    $('#last_events').height($('#message_board').height())
  }
});
