export default function support_form() {
  $('#support-modal a').click(function(e) {
    $(this).tab('show');
  }).on('shown.bs.tab', function (e) {
    var target   = $(e.target)
    var textarea = $('.support-textarea')

    if (target.hasClass('btn')) {
      return false;
    }

    if (target) {
      textarea.attr('placeholder', target.data('placeholder'));
      $('#support-type').val(target.data('id'));
    }
  });

  $('#support-modal form.new_contact').bind('ajax:before', function(){
    var loader       = $('.support-loader'),
        textarea     = $('.support-textarea'),
        support_type = $('#support-type');
    textarea.css('border-color', '#ccc');
    if (textarea.val() == '') {
      textarea.css('border-color', 'red');
      return false;
    }
    loader.attr('src', '/images/preloader.gif').fadeIn();
  }).bind('ajax:complete', function() {
    $('#support-modal').modal('hide');
    $('.support-loader').hide();
  }).bind('ajax:success', function() {
    $('.support-textarea').val('');
  });

}
